import React from 'react';

import zakresStyle from './zakres-uslug.module.css';

const ZakresUslug = () => {
	return (
		<div className={zakresStyle.services}>
			<h1 className={zakresStyle.h1}>Zakres usług</h1>
			<div className={zakresStyle.description}>
				<p className={zakresStyle.p}>Jesteśmy mobilnym serwisem opon, zajmujemy się:</p>
				<ul>
					<li>
						naprawą i wymianą opon u klienta w pojazdach ciężarowych, osobowych oraz w maszynach rolniczych
					</li>
					<li>sprzedażą opon do pojazdów ciężarowych oraz osobowych, nowych oraz używanych </li>
					<li>sezonową wymianą opon u klienta (wraz z wyważaniem)</li>
					<li>przechowaniem opon pojazdów osobowych</li>
				</ul>
				<p className={zakresStyle.p}>Zapraszamy do kontaktu pod numerem telefonu 663 774 900.</p>
			</div>
		</div>
	);
};

export default ZakresUslug;
