import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import classes from './notmatch.module.css';

const NotMatch = ({ location }) => {
	const [ redirect, setRedirect ] = React.useState(false);

	React.useEffect(() => {
		setTimeout(() => {
			setRedirect(true);
		}, 7000);
	}, []);

	return (
		<div
			className={classes.notmatch}
			style={{ width: '65vw', margin: 'auto', marginTop: '150px', textAlign: 'center' }}
		>
			<h1 className={classes.h1}>
				{/* No match for <code>{location.pathname}</code> */}
				Ups... coś musiało pójść nie tak...
			</h1>

			<h2 className={classes.h2}>Za chwilę zostaniesz przeniesiony na</h2>
			<Link className={classes.link} to="/">
				stronę główną
			</Link>
			{redirect ? <Redirect to="/" /> : ''}
		</div>
	);
};

export default NotMatch;
