import React from 'react';

import aboutStyles from './o-mnie.module.css';
import pagra from '../../assets/images/pagra.png';

const Omnie = () => {
	return (
		<div>
			<div className={aboutStyles.about}>
				<img className={aboutStyles.kontaktLogo} src={pagra} alt="pagra mobilny serwis opon" />

				<h3 className={aboutStyles.h3}>
					Zdarzyła Ci się awaria? Dzwoń po pomoc: <br />
					<br />663 774 900<br />
					<br />
					Jesteśmy dostępni całą dobę!
				</h3>
			</div>

			<div className={aboutStyles.description}>
				<div className={aboutStyles.p}>
					Firma <span>PA</span>GRA MOBILNY SERWIS OPON została powołana aby zapełnić lukę na rynku w
					profesjonalnych usługach wulkanizacyjnych.
				</div>
				<div className={aboutStyles.p}>
					Nasz profesjonalny zespół zadba, aby każdy klient, któremu zdarzyła się awaria na trasie, nie
					pozostawał bez pomocy. Wieloletnie doświadczenie naszych pracowników oraz profesjonalny sprzęt
					wylkanizacyjny są tego gwarancją.
				</div>
				<div className={aboutStyles.p}>
					Zajmujemy się przede wszystkim mobilnym serwisem opon. W sytuacji awaryjnej docieramy do klienta i
					pomagamy na miejscu. Specjalizujemy się w obsłudze pojazdów ciężarowych ale także osobowych. W
					sytuacji, gdy opona która uległa awarii nie może zostać naprawiona, oferujemy oponę zastępczą
					ponieważ zajmujemy się także sprzedażą opon do pojazdów ciężarowych i osobowych, zarówno nowych jak
					i używanych.
				</div>
				<div className={aboutStyles.p}>
					Kładziemy olbrzymi nacisk na satysfakcję naszych klientów. Zawszę staramy się aby usługa została
					wykonana profesjonalnie, bo zadowolony klient jest najlepszą reklamą.
				</div>
				<div className={aboutStyles.p}>
					Zachęcamy do kontaktu pod numerem telefonu: <br />
					<br />663 774 900<br />
					<br />Jesteśmy dostępni przez całą dobę przez 7 dni w tygodniu.
				</div>
			</div>
		</div>
	);
};

export default Omnie;
