import React from 'react';
// import {Helmet} from "react-helmet";
// import { Helmet } from 'react-helmet-async';

import kontaktStyle from './kontakt.module.css';

// import kamed from '../../assets/images/kamed450.jpg';
// import medicr from '../../assets/images/medic-r450.png';
// import danmed from '../../assets/images/danmed450.png';
import pagra from '../../assets/images/pagra.png';

const Kontakt = () => {
	return (
		<div className={kontaktStyle.contact}>
			{/* <Helmet>
				<title>PAGRA mobilny serwis opon</title>
				<meta name="description" content="PAGRA mobilny serwis opon - TIR 24h - 663 774 900" />
			</Helmet> */}
			<h1 className={kontaktStyle.h1}>Skontaktuj się z nami</h1>
			<div className={kontaktStyle.description}>
				<div className={kontaktStyle.p}>
					Jeśli przytrafiła się Tobie awaria na trasie, to możesz zadzwonić do nas! Nasz bus serwisowy naprawi
					usterkę na miejscu.
				</div>
			</div>
			<div className={kontaktStyle.kontakt}>
				<img className={kontaktStyle.kontaktLogo} src={pagra} alt="pagra mobilny serwis opon" />
				<div className={kontaktStyle.kontaktDescription}>
					<h2>Zadzwoń do nas</h2>
					<div className={kontaktStyle.address}>
						Jesteśmy do Waszej dyspozycji całą dobę <br />a obszar naszych działań to Wielkopolska.
					</div>

					<a className={kontaktStyle.tel} href="tel:663774900">
						<span>Telefon kontaktowy:</span>
						<p>663 774 900</p>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Kontakt;
