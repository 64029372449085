import React, { createContext, useState } from 'react';

export const ActiveMenuContext = createContext();

export const ActiveMenuProvider = ({ children }) => {
	let [ active, setActive ] = useState(false);

	const clickHandle = () => {
		setActive(!active);
	};

	return <ActiveMenuContext.Provider value={[ active, clickHandle ]}>{children}</ActiveMenuContext.Provider>;
};
