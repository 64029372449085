import React, { /*useState, useEffect,*/ useContext } from 'react';
import { Link } from 'react-router-dom';
import { ActiveMenuContext } from '../../context/ActiveMenuContext';

import './header.css';
import headBrain from '../../assets/images/pagra3.png';

// import data from '../../assets/data/data';

const Header = () => {
	const [ active, clickHandle ] = useContext(ActiveMenuContext);
	// const [ city, setCity ] = useState('w Kostrzynie');

	// useEffect(() => {
	// 	let citys = [ 'w Kostrzynie', 'w Poznaniu', 'w Obornikach' ];
	// 	setInterval(() => {
	// 		setCity(citys[Math.floor(Math.random() * citys.length)]);
	// 	}, 2000);
	// }, []);

	return (
		<header>
			<div className="containerHeader">
				<nav>
					<Link to="/" className={'navbar-brand'}>
						{/* <Link to="/" className={active ? 'navbar-brand active' : 'navbar-brand'}> */}
						<img className="imgHead" src={headBrain} alt="PAGRA Mobilny Serwis Opon" />
					</Link>
					{/* <div>
						<h1 className={'nav-title'}>
							
							<b>
								<span>PA</span>GRA
							</b>
							<br />
						</h1>
						MOBILNY SERWIS OPON
					</div> */}
					<ul className={'nav-list-desktop'}>
						<li className="nav-item" onClick={clickHandle}>
							<Link to="/zakres-uslug-mobilnej-wulkanizacji" className="nav-link">
								Zakres usług
							</Link>
						</li>
						<li className="nav-item" onClick={clickHandle}>
							<Link to="/o-mobilnym-serwisie-opon" className="nav-link">
								O firmie
							</Link>
						</li>
						<li className="nav-item" onClick={clickHandle}>
							<Link to="/kontakt" className="nav-link">
								Kontakt
							</Link>
						</li>
					</ul>
					<ul className={active ? 'nav-list active' : 'nav-list'}>
						<li className="nav-item" onClick={clickHandle}>
							<Link to="/zakres-uslug-mobilnej-wulkanizacji" className="nav-link">
								Zakres usług
							</Link>
						</li>
						<li className="nav-item" onClick={clickHandle}>
							<Link to="/o-mobilnym-serwisie-opon" className="nav-link">
								O firmie
							</Link>
						</li>
						<li className="nav-item" onClick={clickHandle}>
							<Link to="/kontakt" className="nav-link">
								Kontakt
							</Link>
						</li>
					</ul>
					<div className={active ? 'hamburger active' : 'hamburger'} onClick={clickHandle}>
						<span className="line" />
						<span className="line" />
						<span className="line" />
					</div>
				</nav>
			</div>
		</header>
	);
};

export default Header;
