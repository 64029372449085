import React from 'react';
import { Link } from 'react-router-dom';
// import { ActiveMenuContext } from '../../context/ActiveMenuContext';

import homeStyle from './home.module.css';
// import pagra from '../../assets/images/pagra.png';

import Jumbotron from '../Jumbotron/PhoneNumberButton';
// import SlidingText from '../SlidingText/SlidingText';

const IndexPage = () => {
	// const [ active, clickHandle ] = useContext(ActiveMenuContext);

	return (
		<div className={homeStyle.hp}>
			<Jumbotron />
			{/* <SlidingText /> */}
			{/* <div className={homeStyle.hero} />
			<button className={homeStyle.imgBorder} onClick={clickHandle}>
				<h1 className={homeStyle.h1}>Zdarzyła się awaria? Zadzwoń po pomoc</h1>
				<img
					className={active ? homeStyle.imgHead : homeStyle.imgHead}
					src={pagra}
					alt="PAGRA Mobilny Serwis Oponnn"
				/>
			</button> */}
			{/* <div className={homeStyle.card}>
				<h1 className={homeStyle.h1}>PAGRA</h1>
				<h3 className={homeStyle.h3}>MOBILNY SERWIS OPON</h3>
			</div> */}
			<div className={homeStyle.more}>
				<Link to="/o-mobilnym-serwisie-opon" className={homeStyle.link}>
					<div className={homeStyle.item}>
						<h4>
							<span>PA</span>GRA - mobilny serwis opon
						</h4>
						<div className={homeStyle.moreDesc}>
							Zajmujemy się profesjonalnym serwisem opon do aut cięzarowych i osobowych...
						</div>
						<div className={homeStyle.moreLink}>czytaj więcej</div>
					</div>
				</Link>
				<Link to="/zakres-uslug-mobilnej-wulkanizacji" className={homeStyle.link}>
					<div className={homeStyle.item}>
						<h4>Zakres usług</h4>
						<div className={homeStyle.moreDesc}>
							serwis opon u klienta, sprzedaż opon nowych i używanych do aut ciężarowych i osobowych
						</div>
						<div className={homeStyle.moreLink}>więcej o usługach</div>
					</div>
				</Link>
				<Link to="/kontakt" className={homeStyle.link}>
					<div className={homeStyle.item}>
						<h4>Skontaktuj się</h4>
						<div className={homeStyle.moreDesc}>
							Jesteśmy dostępni całą dobę pod numerem telefonu: <br />
							<br />663 774 900
						</div>
						<div className={homeStyle.moreLink}>więcej</div>
					</div>
				</Link>
			</div>
		</div>
	);
};
export default IndexPage;
