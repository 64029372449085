import React from 'react';
import './phoneNumber.css';

import { ImPhone } from 'react-icons/im';
// import pagra2Logo from './assets/images/pagra2.png';
import pagraLogo from '../../assets/images/pagra.png';

const PhoneNumberButton = () => {
	return (
		<div className="jumbotron">
			<div className="container">
				<img className="img" src={pagraLogo} alt="PAGRA mobilny serwis opon" />
				<a className="number" href="tel:663774900">
					<ImPhone />
					<div>663 774 900</div>
					<div className="shine" />
				</a>
			</div>
		</div>
	);
};

export default PhoneNumberButton;
